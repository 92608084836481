#contacto {
  position: relative;
  width: 100%;
  height: fit-content;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/images/web/contacto-bg.jpg');
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  .div-formulario {
    margin: 4rem 0;
    height: fit-content;
    width: 70%;
    background-color: rgba(0, 0, 0, 0.541);
    h3 {
      color: #fff;
      // font-family: 'Sacramento', cursive;
      font-size: calc(0.8rem + 0.5*(100vw - 20rem)/60);
      margin-top: 0;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-weight: 200;
      text-align: center;
    }
    h2 {
      color: #fff;
      font-family: 'Lora', serif;
      font-size: calc(1.5rem + 0.8*(100vw - 20rem)/60);
      letter-spacing: calc(1px + 0.7 * (100vw - 50rem) / 120);
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 1rem;
      text-align: center;
    }
    .formulario {
      padding: 1rem 5rem;
      width: 65%;
      margin: auto;
      margin-bottom: 1rem;
      background-color: rgba(255, 255, 255, 0.9);
      .form-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        label {
          color: #000;
          font-weight: 500;
          letter-spacing: 2px;
          font-size: 0.8rem;
        }
        input, textarea {
          border: 0;
          background-color: transparent;
          border-bottom: 1px solid black;
          font-family: 'Montserrat', sans-serif;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .form-button {
        width: 100%;
        display: flex;
        justify-content: center;
        .btn-link {
          margin: 0;
        }
      }
    }
  }
  @media only screen and (max-width: $md-max) {
    .div-formulario {
      width: 90%;
      .formulario {
        width: 65%;
        padding: 1rem 2rem;
      }
    }
  }
}