.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  .logo {
    max-width: 6rem;
    height: auto;
  }
  &-text {
    margin: 0.2rem 0;
  }
  &-icons {
    img {
      height: 24px;
      width: 24px;
      margin: 0.3rem 0.5rem;
    }
  }
  &-firma {
    padding-top: 0.5rem;
    font-size: 0.7rem;
    a {
      text-decoration: none;
      color: #000;
    }
  }
}