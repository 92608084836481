@import '_variables';

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  &-logo {
    height: 10rem;
    width: auto;
  }
  &-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 5px 0 0 0;
    &-item {
      a {
        color: $secondary;
        font-size: 14px;
        text-decoration: none;
      }
      margin: 0 0.5rem;
    }
  }
}