@import '_navbar';
@import '_footer';
@import '_contacto';
html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}


  
.wave {
  background-image: url('/images/web/wave.svg');
  height: 3.5rem;
  width: 100%;
  background-size: contain;
  position: absolute;
  background-position-y: bottom;
  background-position-x: center;
  background-repeat-y: no-repeat;
  z-index: 99;
  &-top {
    top: -1px;
    transform: rotate(180deg);
  }
  &-bottom {
    bottom: -1px;
  }
}

.titulo {
  font-family: 'Lora', serif;
  font-size: calc(3rem + 0.5*(100vw - 20rem)/60);
  margin: 0;
}

.btn-link {
  color: black;
  text-decoration: none;
  background-color: white;
  padding: 0.5rem;
  box-shadow: 0px 0px 8px 2px #dedede;
  font-size: 0.8rem;
  margin-top: 1rem;
  transition: all 300ms ease;
  &:hover {
    background-color: black;
    color: white;
  }
}

.text-center {
  text-align: center;
}

.container {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

.text-info {
  font-size: calc(1rem + 0.2*(100vw - 20rem)/60);
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 300;
  font-style: italic;
  text-align: center;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(32, 32, 32); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.wsap-float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;

  svg {
    margin-top:16px;
    fill:#fff;
    width: 24px;
  }
}

